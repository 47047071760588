<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isUpdateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    width="50%"
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-update-sidebar-active', val)"
  ><template #default="{ hide }">
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        show the Mail Notification Details
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hide"
      />
    </div>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Skill Name -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="skill.title"
              disabled
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Skill Slug -->
        <!-- Skill Type -->
        <validation-provider
          #default="validationContext"
          name="body"
          rules="required"
        >
          <b-form-group
            label="Body"
            label-for="body"
          >
            <quill-editor
              v-model="skill.body"  
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="body"
          rules="required"
        >
          <b-form-group
            label="Image"
            label-for="body"
          >
            <b-img :src="skill.image"> </b-img>

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="isVerified"
          rules=""
        >
          <b-form-group
            label=""
            label-for="isVerified"
          >
            <b-form-checkbox
              v-model="skill.is_verified"
              disabled
            >
              Is Verified
            </b-form-checkbox>

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            </div></b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="isActive"
          rules=""
        >
          <b-form-group
            label=""
            label-for="isVerified"
          >
            <b-form-checkbox
              v-model="skill.is_active"
              disabled
            >
              Is Active
            </b-form-checkbox>

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            </div></b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Created at"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="skill.createdAt"
              disabled
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Close
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </template></b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
  BImg,
} from "bootstrap-vue";
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import useSkillsList from "./useMailNotificationsList";

export default {
  components: {
    BSidebar,
    quillEditor,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BImg,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isUpdateSidebarActive: {
      required: true,
      type: Boolean,
    },
    skill: {
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    const { skillStatuses, updateSkill } = useSkillsList();

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    const onSubmit = async () => {
      const response = await updateSkill(props.skill);
      if (typeof response !== "undefined") {
        emit("refetch-data");
        emit("update:is-update-sidebar-active", false);
      }
    };

    return {
      refFormObserver,
      getValidationState,
      skillStatuses,
      resetForm,
      onSubmit,
    };
  },
};
</script>
